import React from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import Button from '../../components/Button';
import Subfooter from '../../components/Subfooter/index';
import Hero from '../../components/Hero';
import Modal from '../../components/Modal';
import Video from '../../components/Video';
import PartnershipPromo from '../../components/PartnershipPromo';
import imgCardRowBackground from '../../images/img-home-feature-career-bg.png';

const CareersLanding = () => {
  return (
    <Layout title="Students">
      <Hero
        mediaSource="img-lead-students.jpg"
        textOverlay={{ heading: 'Career Profiles', pageTitle: 'STUDENTS' }}
      />
      <Section
        style={{
          backgroundImage: `url(${imgCardRowBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {/* Intro  */}
        <Container fullWidth>
          <Row>
            <h2 className="ml-0.5">
              Meet Diverse Problem-Solvers Engineering a Better World
            </h2>
            <Column size={9}>
              <p>
                Immerse students in the ever-expanding world of engineering by
                exploring the lives of real people with real stories of using
                problem-solving skills to improve lives. Open doorways for your
                students into every imaginable industry with career resources
                that highlight a wide range of unique STEM professionals.
              </p>
              <p>
                Explore career profiles of people who are using STEM skills they
                learned in school to become the kind of problem solvers that
                make a difference.
              </p>
              <Link
                to="https://stemcareerscoalition.org/careers-portal"
                className="text-bold"
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </Link>
            </Column>
          </Row>
          {/* Career profile cards */}
          <Row className="students__cards">
            <Column size={4}>
              <Image
                filename="img-career-profile1.jpg"
                onClick={() =>
                  navigate('/career-profiles/supplier-quality-launch-leader/')
                }
              />
              <h3>Supplier Quality Launch Leader, GM</h3>
              <p>
                In light of widespread closures of manufacturing plants and
                parts suppliers due to the pandemic, Alex and his GM colleagues
                collaborated with an outside company to help produce
                ventilators, using their manufacturing skills for the greater
                good.
              </p>
              <Button to="/career-profiles/supplier-quality-launch-leader/">
                Learn More
              </Button>
            </Column>
            <Column size={4}>
              <Image
                filename="career-thumb-chaffin.jpg"
                onClick={() => navigate('/career-profiles/senior-scientist/')}
              />
              <h3>Vice President of Technology</h3>
              <p>
                The VP of Technology oversees a large team comprising a wide
                variety of engineering disciplines and sciences, all coming
                together to innovate life-changing tech. They use a strong
                fundamental understanding of math, physics, and chemistry to
                develop new devices capable of improving lives.
              </p>
              <Button to="/career-profiles/senior-scientist/">
                Learn More
              </Button>
            </Column>
            <Column size={4}>
              <Image
                filename="img-career-profile2.jpg"
                onClick={() =>
                  navigate('/career-profiles/field-cost-controller/')
                }
              />
              <h3>Field Cost Controller</h3>
              <p>
                Cost Controllers analyze the relationship between how well a
                project is progressing, and how much is being spent on that
                project. Working directly on the job site allows them to
                communicate and collaborate closely with the rest of the Project
                Management team.
              </p>
              <Button to="/career-profiles/field-cost-controller/">
                Learn More
              </Button>
            </Column>
          </Row>
          <Row className="students__cards">
            <Column size={4}>
              <Image
                filename="career-thumb-ruppe.jpg"
                onClick={() =>
                  navigate(
                    '/career-profiles/distributed-energy-resources-engineer/'
                  )
                }
              />
              <h3 className="long-text">
                Distributed Energy Resources Engineer
              </h3>
              <p>
                Distributed Energy Resources Engineers ensure that homes and
                businesses continue to have a safe and steady supply of energy,
                whether through solar power or battery. They use their expertise
                in renewable and non-renewable energy resources to satisfy
                customer needs in a way that is most sustainable.
              </p>
              <Button to="/career-profiles/distributed-energy-resources-engineer/">
                Learn More
              </Button>
            </Column>
            <Column size={4}>
              <Image
                filename="img-career-profile3.jpg"
                onClick={() =>
                  navigate('/career-profiles/engineering-project-manager/')
                }
              />
              <h3>Engineering Project Manager</h3>
              <p>
                Engineering Project Managers enable Mechanical Engineers, Civil
                Engineers, and Electrical Engineers to collaborate on solutions
                for strengthening natural gas and oil infrastructure. They are
                skilled problem-solvers with engineering knowledge who love
                taking things apart and putting them back together again.
              </p>
              <Button to="/career-profiles/engineering-project-manager/">
                Learn More
              </Button>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-1">
        <PartnershipPromo />
      </Section>
      <Section className="pb-0 pt-0">
        <Subfooter />
      </Section>
    </Layout>
  );
};

export default CareersLanding;
